import { action } from 'typesafe-actions'
import { AllocateFundActionTypes } from './types'

export const allocateFundsRequest = (payload: any) =>
  action(AllocateFundActionTypes.ALLOCATE_FUNDS_REQUEST, payload)
export const allocateFundSuccess = (response: any) =>
  action(AllocateFundActionTypes.ALLOCATE_FUNDS_SUCCESS, response)
export const allocateFundFailure = (error: any) =>
  action(AllocateFundActionTypes.ALLOCATE_FUNDS_FAILURE, error)
export const fundTransactionsRequest = (payload: any) =>
  action(AllocateFundActionTypes.FUNDS_TRANSACTIONS_REQUEST, payload)
export const fundTransactionsSuccess = (response: any) =>
  action(AllocateFundActionTypes.FUNDS_TRANSACTIONS_SUCCESS, response)
export const fundTransactionsFailure = (error: any) =>
  action(AllocateFundActionTypes.FUNDS_TRANSACTIONS_FAILURE, error)
export const clearStates = () => action(AllocateFundActionTypes.CLEAR_STATES)
export const verifyFundAllocationRequest = (payload: any) =>
  action(AllocateFundActionTypes.VERIFY_FUNDS_ALLOCATION_REQUEST, payload)
export const verifyFundAllocationSuccess = (response: any) =>
  action(AllocateFundActionTypes.VERIFY_FUNDS_ALLOCATION_SUCCESS, response)
export const verifyFundAllocationFailure = (error: any) =>
  action(AllocateFundActionTypes.VERIFY_FUNDS_ALLOCATION_FAILURE, error)
export const exportTransactionRequest = (payload: any) =>
  action(AllocateFundActionTypes.EXPORT_TRANSACTIONS_REQUEST, payload);
export const exportTransactionSuccess = (response: any) =>
  action(AllocateFundActionTypes.EXPORT_TRANSACTIONS_SUCCESS, response)
export const exportTransactionFailure = (error: any) =>
  action(AllocateFundActionTypes.EXPORT_TRANSACTIONS_FAILURE, error)