import React, { useState } from 'react'
import { Row, Col, Table, Tag } from 'antd'
import { appSelector } from '../../helpers/appSelector'
import Filters from './Filters'
import moment from 'moment'
import { roles, timeZones } from '../../helpers/constants'
import { MerchantData, FundTransaction as TT } from "../../interfaces";
import { isEmpty } from '../../helpers/isEmpty'
import { clearStates, fundTransactionsRequest } from '../../store/allocate-funds'
import { AppDispatch } from '../../helpers/appDispatch'
import { useDispatch } from 'react-redux'
import Accounts from '../../pages/_accounts'
import { getMerchantVASAccountBalance } from '../../store/home'

interface Props {
  onLoadMore(page: any, pageSize: any): void
  translate: any
  total: number
  setOpenModal: () => void
  onClickRow(transactionID: number): void
}

const AllocateFundsDashboard: React.FC<Props> = (props) => {
  const dispatch: AppDispatch = useDispatch()
  const {  onLoadMore, translate, total, setOpenModal, onClickRow } = props
  const { transactions, isFetching} = appSelector((state) => state.allocateFundState);
  const { user } = appSelector((state) => state.auth)
  const reports = appSelector((state) => state.reports)
  const home = appSelector((state) => state.home)
  const { merchants } = reports
  const [merchant, setMerchant] = useState<MerchantData | null>(null)
  const [periodFrom, setPeriodFrom] = useState('')
  const [periodTo, setPeriodTo] = useState('')
  const [skip, setSkip] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [isSearching, setIsSearching] = useState(false)
  const {loadingAccounts, merchantAccounts } = home

  let role: any;
  if (user) {
    role = user.roles.find((r) => r === roles.SuperMerchant);
  } else {
    role = roles.SuperMerchant;
  }
  
  const payload = {
    periodFrom: periodFrom,
    periodTo: periodTo,
    merchant: merchant ? merchant?.userId : 0,
    skip: skip,
    pageSize: pageSize
  }
    console.log({merchant});
  const onSearch = (values: any) => {
    setIsSearching(true)
    const { merchant, periodFrom, periodTo } = values
    let pFrom: string = '',
      pTo: string = ''
    let m: MerchantData | undefined = undefined
  
    if (!isEmpty(periodFrom) && !isEmpty(periodTo)) {
      pFrom = moment(periodFrom).format('MM/DD/YYYY')
      pTo = moment(periodTo).format('MM/DD/YYYY')
      setPeriodFrom(pFrom)
      setPeriodTo(pTo)
    }
    if (!isEmpty(merchant)) {
      m = merchants.find((m) => m.merchantId === merchant)
      if (m !== undefined) {
        setMerchant(m)

        dispatch(getMerchantVASAccountBalance(m !== undefined ? m?.userId?.toString() : ''))
      } else {
        setMerchant(null)
      }
    }
    payload.periodFrom = pFrom
    payload.periodTo = pTo
    payload.merchant = m !== undefined ? m?.userId : 0
    dispatch(clearStates())
    dispatch(fundTransactionsRequest(payload))
  }
  
  const onReset = (form: any) => {
    form.resetFields()
    payload.periodFrom = ''
    payload.periodTo = ''
    payload.merchant = 0
    setIsSearching(false)
  }

  const columns: any = [
    {
      title: <span style={{ fontSize: "2rem", color: "#868686" }}>&bull;</span>,
      dataIndex: "status",
      key: "status",
      align: "center",
      className: "column-text",
      render: (status: string) => {
        return <span style={{ fontSize: "2rem", color: "#868686" }}>&bull;</span>;
      },
    },
    {
      title: `${translate("general.amount")}`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
      className: "column-text",
    },
    {
      title: `${translate("general.debitAccount")}`,
      dataIndex: "debitAccount",
      key: "debitAccount",
      align: "center",
      className: "column-text",
    },
    {
      title: `${translate("general.creditAccount")}`,
      dataIndex: "creditAccount",
      key: "creditAccount",
      align: "center",
      className: "column-text",
    },
    {
      title: `${translate("general.createdAt")}`,
      dataIndex: "trxDate",
      key: "trxdate",
      align: "center",
      className: "column-text",
      responsive: ["lg"],
      render: (date: string) => {
        // const d = moment(date, 'MM/DD/YYYY HH:mm:ss')
        const d = moment(date)
          .tz(timeZones.kinshasa)
          .format(`MMMM D, YYYY (h:mm a)`);
        return <span>{d}</span>;
      },
    },
    {
      title: `${translate("general.status")}`,
      dataIndex: "status",
      key: "status",
      align: "center",
      className: "column-text",
      responsive: ["lg"]
    },
    {
      title: `${translate("general.approvalStatus")}`,
      dataIndex: "approvalStatusId",
      key: "approvalStatusId",
      align: "center",
      className: "column-text",
      responsive: ["lg"],
      render: (approvalStatusId: number) => {
        switch (approvalStatusId) {
          case 1:
            return (
              <Tag key={approvalStatusId}  style={{ color: '#35b9e6', backgroundColor:'transparent', border:'1px solid #35b9e6', borderRadius: '10px' }}>
                {'Submitted to Checker'}
              </Tag>
            );
          case 2:
            return (
              <Tag key={approvalStatusId} className="table-tag-merchant">
                {'Approved'}
              </Tag>
            );
          case 3:
            return (
              <Tag key={approvalStatusId}  style={{ color: 'red', backgroundColor:'transparent', border:'1px solid red', borderRadius: '10px' }}>
                {'Rejected'}
              </Tag>
            );
          default:
            break;
        }
        
      },
    }
  ];

  let dataSource: TT[] = [];
  for (let transaction of transactions) {
    dataSource.push({
      amount: `${transaction.currency} ${transaction.amount}`,
      transactionId: transaction.transactionId,
      trxDate: transaction.trxDate,
      status: transaction.status,
      debitAccount: transaction.debitAccount,
      creditAccount: transaction.creditAccount,
      currency: transaction.status,
      approvalStatusId: transaction.approvalStatusId,
      approvalStatus: transaction.approvalStatus
    });
  }

  return (
    <Row gutter={20}>
      <Col span={24}>
        <div className="table-padding ">
          {role !== undefined && role === roles.SuperMerchant && merchant !== undefined && merchant !== null && !loadingAccounts? (
            <div style={{ display: "flex", justifyContent:"flex-end" }}>
              <span className="align-self-center" style={{ fontSize:"14px", marginRight: "5px"}}>{`${merchant? merchant.name: ''} ${translate("general.balance")}:`}</span>
              <Accounts accounts={merchantAccounts} loading={loadingAccounts} />
            </div>
          ): null}
          <Filters
            merchants={merchants}
            onSearch={onSearch}
            onReset={onReset}
            translate={translate}
            setOpenModal={setOpenModal}
          />
        </div>
        <div className="table-padding">
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered
            className="tranaction-table"
            onRow={(t: TT) => ({
              onClick: () => onClickRow(t.transactionId),
            })}
            pagination={{
              onChange: (page, pageSize) => {
                onLoadMore(page, pageSize)
              },
              total: total,
              showTotal: (_, range) => {
                const tran = translate(`general.pagination`)
                let t = tran.replace(`%d`, `${range[0]} - ${range[1]}`)
                let s = t.replace(`%s`, total)
                return s
              },
              showSizeChanger: false
            }}
            loading={isFetching}
            />
          </div>
      </Col>
    </Row>
  )
}

export default AllocateFundsDashboard
