import React, { Suspense, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Spin, Row, Form } from 'antd'
import { useDispatch } from 'react-redux'
import { appSelector } from '../helpers/appSelector'
import { AppDispatch } from '../helpers/appDispatch'
import { FundTransaction, MerchantData } from '../interfaces'
import { allocateFundsRequest, clearStates, fundTransactionsRequest } from '../store/allocate-funds'
import { isEmpty } from '../helpers/isEmpty'
import { useTranslation } from 'react-i18next'
import { getMerchantsRequest } from '../store/reports'
import FundAllocation from '../components/allocateFunds'
import AllocateFundsDashboard from '../components/allocateFunds/dashboard'
import TransactionDetail from '../components/allocateFunds/TransactionDetail'

const { Content } = Layout

const AllocateFunds = () => {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const reports = appSelector((state) => state.reports)
  const allocateFundState = appSelector((state) => state.allocateFundState)
  const [merchants, setMerchants] = useState<MerchantData[]>(reports.merchants)
  const { isSubmitting, success, error } = allocateFundState
  const [pageSize, setPageSize] = useState(10)
  const [skip, setSkip] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [switchView, setSwitchView] = useState(false)
  const [trx, setTrx] = useState<FundTransaction | null>(null)

  const payload = {
    periodFrom: '',
    periodTo: '',
    merchant: 0,
    skip: skip,
    pageSize: pageSize
  }
  const params = {
    currency: '',
    merchantId: 0,
    amount: 0
  }
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(fundTransactionsRequest(payload));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    const { merchants } = reports
    if (isEmpty(merchants)) {
      dispatch(getMerchantsRequest())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { merchants } = reports
    setMerchants(merchants)
  }, [reports])

  const onAllocateFunds = (values: any) => {
    const { merchant, amount, currency } = values
    params.merchantId = merchant
    params.amount = amount
    params.currency = currency
    dispatch(allocateFundsRequest(params))
  }

  const onLoadMore = (page: any, size: any) => {
    setSkip(0)
    setPageSize(size)
    payload.skip = page - 1
    setSkip(payload.skip)
    dispatch(fundTransactionsRequest(payload));
  }
  const onToggleModal = () => {
    setShowModal(!showModal)
    dispatch(fundTransactionsRequest(payload));
    dispatch(clearStates())
  }
  
  const onClickRow = (transactionID: number) => {
    setSwitchView(!switchView)
    if (allocateFundState.transactions && !isEmpty(allocateFundState.transactions)) {
      const trx = allocateFundState.transactions.find(
        (t) => t.transactionId === transactionID
      )
      if (trx !== undefined) {
        setTrx(trx)
      }
    }
  }

  const onCloseScreen = () => {
    if (success) {
      dispatch(fundTransactionsRequest(payload));
      dispatch(clearStates());
    }
    setSwitchView(!switchView)
  }

  return (
    <div className="padding-box">
      <Content className="site-layout-background site-box">
        <Suspense
          fallback={
            <Row className="suspense-container">
              <div style={{ marginTop: '200px' }}>
                <Spin />
              </div>
            </Row>
          }
        >
          {!switchView ? (
            <AllocateFundsDashboard
              onLoadMore={(page: any, pageSize: any) => onLoadMore(page, pageSize)}
              translate={t}
              total={0}
              setOpenModal={onToggleModal}
              onClickRow={onClickRow}
            />
            )
            :
            (
            <TransactionDetail
              onCloseScreen={onCloseScreen}
              transaction={trx!}
              isDownloading={false}
              translate={t}
              />
            )
          }
          <FundAllocation
            onSubmit={onAllocateFunds}
            merchants={merchants} 
            isSubmitting={isSubmitting}
            Form={Form}
            form={form}
            errors={error}
            success={success}
            translate={t}
            showModal={showModal}
            onToggleModal={onToggleModal}
          />
            
        </Suspense>
      </Content>
    </div>
  )
}

export default withRouter(AllocateFunds)
