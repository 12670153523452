import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { AllocateFundActionTypes } from './types'
import { callApiPost } from '../../utils/api'
import {
  allocateFundSuccess,
  allocateFundFailure,
  fundTransactionsFailure,
  fundTransactionsSuccess,
  verifyFundAllocationFailure,
  verifyFundAllocationSuccess,
  exportTransactionSuccess,
  exportTransactionFailure
} from './actions'
import { path } from '../../helpers/path'
import { logout } from '../auth'
import { DataStream } from '../../interfaces'

function* allocateFunds({ payload }: { type: string; payload: any }): any {
  try {
    const res = yield call(callApiPost, 'payments/allocatefundstomerchant', payload)
    yield put(allocateFundSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      console.log(err)
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else if (err.response.status === 500) {
        yield put(allocateFundFailure('An unknwon error occurred'))
      } else{
        yield put(allocateFundFailure(err.response.data))
      }
    } else {
      yield put(allocateFundFailure('An unknwon error occurred'))
    }
  }
}

function* fundTransactions({ payload }: { type: string; payload: any }): any {
  try {
    const res = yield call(callApiPost, 'payments/fundtransactions', payload)
    yield put(fundTransactionsSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      console.log(err)
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else if (err.response.status === 500) {
        yield put(fundTransactionsFailure('An unknwon error occurred'))
      } else{
        yield put(fundTransactionsFailure(err.response.data))
      }
    } else {
      yield put(fundTransactionsFailure('An unknwon error occurred'))
    }
  }
}

function* verifyFundAllocation({ payload }: { type: string; payload: any }): any {
  try {
    const res = yield call(callApiPost, 'payments/approvefundallocation', payload)
    yield put(verifyFundAllocationSuccess(res.data))
  } catch (err: any) {
    if (err && err.response) {
      console.log(err)
      if (err.response.status === 401) {
        yield put(logout())
        localStorage.removeItem('user')
        localStorage.removeItem('persist:root')
        localStorage.clear()
        window.location.href = path.login
      } else if (err.response.status === 500) {
        yield put(verifyFundAllocationFailure('An unknwon error occurred'))
      } else{
        yield put(verifyFundAllocationFailure(err.response.data))
      }
    } else {
      yield put(verifyFundAllocationFailure('An unknwon error occurred'))
    }
  }
}

function* exportFundAllocations({
  payload,
}: {
  type: string;
  payload: any;
}): any {
  try {
    const res = yield call(
      callApiPost,
      `reports/exportfundallocation`,
      payload
    );
    if (res.status === 200) {
      yield put(exportTransactionSuccess(res.data));

      let file: DataStream = res.data;
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${file.fileContents}`;
      link.download = file.fileDownloadName;
      link.click();
    }
  } catch (err: any) {
    if (err && err.response) {
      if (err.response.status === 401) {
        yield put(logout());
        localStorage.removeItem("user");
        localStorage.removeItem("persist:root");
        localStorage.clear();
        window.location.href = path.login;
      } else {
        yield put(exportTransactionFailure(err.response.data));
      }
    } else {
      yield put(exportTransactionFailure("An unknwon error occurred"));
    }
  }
}

function* watchAllocateFunds() {
  yield takeEvery(AllocateFundActionTypes.ALLOCATE_FUNDS_REQUEST, allocateFunds)
}
function* watchFundTransactions() {
  yield takeEvery(AllocateFundActionTypes.FUNDS_TRANSACTIONS_REQUEST, fundTransactions)
}
function* watchVerifyFundAllocation() {
  yield takeEvery(AllocateFundActionTypes.VERIFY_FUNDS_ALLOCATION_REQUEST, verifyFundAllocation)
}
function* watchExportFundAllocations() {
  yield takeEvery(AllocateFundActionTypes.EXPORT_TRANSACTIONS_REQUEST, exportFundAllocations)
}

function* allocateFundsSaga(): Generator {
  yield all([
    fork(watchAllocateFunds),
    fork(watchFundTransactions),
    fork(watchVerifyFundAllocation),
    fork(watchExportFundAllocations)
  ])
}

export { allocateFundsSaga }
