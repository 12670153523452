import React from 'react'
import { Spin, Row } from 'antd'
import { Account } from '../interfaces'

interface Props {
  accounts: Account[]
  loading: boolean
}

const Accounts = (props: Props) => {
  const { accounts, loading }= props

  return (
    <div>
      {loading ? (
        <Row className="suspense-container">
          <Spin style={{ marginTop: '0px'}} />
        </Row >
      )
      : (
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div className='mr-5 account-balance usd-account'>
            <h3>
              <span style={{ fontWeight: "bold", marginRight:"2px" }}>USD</span>
              <span style={{ fontWeight: "normal" }}>{accounts && accounts.length ?  accounts.filter(x => x.currency === 'USD')[0]?.availableBalance : 0}</span>
            </h3>
          </div>
          <div className='mr-5 account-balance cdf-account'>
            <h3>
              <span style={{ fontWeight: "bold", marginRight:"2px" }}>CDF</span>
              <span style={{ fontWeight: "normal" }}>{accounts && accounts.length ?  accounts.filter(x => x.currency === 'CDF')[0]?.availableBalance : 0}</span>
            </h3>
          </div>
        </div>
      )}
    </div>
  )
}

export default Accounts
