import { FundTransaction } from "../../interfaces/AllocateFunds"

export enum AllocateFundActionTypes {
  ALLOCATE_FUNDS_REQUEST = '@@allocate-funds/ALLOCATE_FUNDS_REQUEST',
  ALLOCATE_FUNDS_SUCCESS = '@@allocate-funds/ALLOCATE_FUNDS_SUCCESS',
  ALLOCATE_FUNDS_FAILURE = '@@allocate-funds/ALLOCATE_FUNDS_FAILURE',
  FUNDS_TRANSACTIONS_REQUEST = '@@allocate-funds/FUNDS_TRANSACTIONS_REQUEST',
  FUNDS_TRANSACTIONS_SUCCESS = '@@allocate-funds/FUNDS_TRANSACTIONS_SUCCESS',
  FUNDS_TRANSACTIONS_FAILURE = '@@allocate-funds/FUNDS_TRANSACTIONS_FAILURE',
  CLEAR_STATES = '@@allocate-funds/CLEAR_STATES',
  VERIFY_FUNDS_ALLOCATION_REQUEST = '@@allocate-funds/VERIFY_FUNDS_ALLOCATION_REQUEST',
  VERIFY_FUNDS_ALLOCATION_SUCCESS = '@@allocate-funds/VERIFY_FUNDS_ALLOCATION_SUCCESS',
  VERIFY_FUNDS_ALLOCATION_FAILURE= '@@allocate-funds/VERIFY_FUNDS_ALLOCATION_FAILURE',
  EXPORT_TRANSACTIONS_REQUEST= '@@allocate-funds/EXPORT_TRANSACTIONS_REQUEST',
  EXPORT_TRANSACTIONS_SUCCESS= '@@allocate-funds/EXPORT_TRANSACTIONS_SUCCESS',
  EXPORT_TRANSACTIONS_FAILURE= '@@allocate-funds/EXPORT_TRANSACTIONS_FAILURE'
}

export type AllocateFundState = {
  readonly isSubmitting: boolean
  readonly isVerifying: boolean
  readonly isFetching: boolean
  readonly success: boolean
  readonly failure: boolean
  readonly error: any
  readonly transactions: FundTransaction[]
}
