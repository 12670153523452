import React, { useState } from 'react'
import { Row, Col, Divider, Button, List, /*, Avatar*/ 
Alert,
Form} from 'antd'
import { XCircle } from 'react-feather'
import { FundTransaction } from '../../interfaces'
import { transactionStatus, timeZones, roles } from '../../helpers/constants'
import moment from 'moment-timezone'
import { appSelector } from '../../helpers/appSelector'
import { AppDispatch } from '../../helpers/appDispatch'
import { useDispatch } from 'react-redux'
import { verifyFundAllocationRequest } from '../../store/allocate-funds'
import TextArea from 'antd/lib/input/TextArea'
interface TransactionDetailProps {
  onCloseScreen(): void
  transaction: FundTransaction
  isDownloading: boolean
  translate: any
}

const TransactionDetail: React.FC<TransactionDetailProps> = ({
  onCloseScreen,
  transaction,
  isDownloading,
  translate,
}) => {
  const allocateFundState = appSelector((state) => state.allocateFundState)
  const { user } = appSelector((state) => state.auth);
  const { success, error } = allocateFundState
  const dispatch: AppDispatch = useDispatch()
  const [formData, setFormData] = useState({ reason: '' });

  let classname = ''
  switch (transaction.status) {
    case 'SUCCESS':
      classname = 'trans-status-success'
      break
    case transactionStatus.CANCELED:
      classname = 'trans-status-canceled'
      break
    case transactionStatus.DECLINED:
      classname = 'trans-status-declined'
      break
    default:
      classname = 'trans-status-canceled'
  }

  const params = {
    transactionId: 0,
    approvalStatusId: 0,
    reason: ''
  }

  let role;
  if (user) {
    role = user.roles.find((r) => r === roles.SuperMerchant);
  } else {
    role = roles.SuperMerchant;
  }

  const onApproveAllocation = (values: any) => {
    params.transactionId = transaction.transactionId
    params.approvalStatusId = 2
    params.reason = formData.reason
    dispatch(verifyFundAllocationRequest(params))
  }

  const onRejectAllocation = (values: any) => {
    console.log({ formData });
    params.transactionId = transaction.transactionId
    params.approvalStatusId = 3
    params.reason = formData.reason
    dispatch(verifyFundAllocationRequest(params))
  }

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Form
      >
      <Row gutter={10}>
        <Col span={24}>
          <div className="upper-header">
            <h4>{translate('general.transactionDetails')}</h4>
            <h6>
              <XCircle size={30} onClick={() => onCloseScreen()} style={{ cursor: 'pointer' }} />
            </h6>
          </div>
        </Col>
      </Row>
      <Row gutter={10} className="margin-top-small">
        <Divider />
      </Row>
      <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Col span={12} sm={24} xs={24} md={12}>
          {success ? (
            <div style={{ marginBottom: 20 }}>
              <Alert
                message="Verification Successful"
                type="success"
              />
            </div>
          ) : null}
          {error ? (
            <div style={{ marginBottom: 20 }}>
              <Alert message={JSON.stringify(error)} type="error" />
            </div>
            ) : null}

          {role !== undefined && role === roles.SuperMerchant && transaction.approvalStatusId === 1 ?
            (
            <div>
              <div className="trans-detail-header-box">
                <Button
                  loading={false}
                  style={{ color: 'white', backgroundColor: '#f55c29', borderColor: '#f55c29'}}
                  type="primary"
                  className="resetButton"
                  key="submit"
                  onClick={onRejectAllocation}
                  htmlType="button"
                >
                  {translate('general.reject')}
                </Button>
                <Button
                  loading={false}
                  style={{ color: 'white', backgroundColor: '#35b9e6' }}
                  type='primary'
                  key="submit"
                  onClick={onApproveAllocation}
                  htmlType="button"
                >
                  {translate('general.approve')}
                </Button>
              </div>
            </div>
            )
            : null}
          <div className="trans-detail-info">
            <div className="trans-amount">
              <h4>{translate('general.amount')}</h4>
              <h2>{`${transaction.currency} ${parseFloat(transaction.amount).toFixed(2)}`}</h2>
            </div>
            <div className="trans-status">
              <Button className={classname}>
                {translate(`${transaction.status.toLowerCase()}`).toUpperCase()}
              </Button>
            </div>
          </div>
          <div className="trans-detail">
            <List className="list">
              <List.Item>
                <h4 className="key"> {translate('general.transactionId')}</h4>
                <h4 className="value">{transaction.transactionId}</h4>
              </List.Item>
              <List.Item>
                <h4 className="key">{translate('general.debitAccount')}</h4>
                <h4 className="value">{transaction.debitAccount}</h4>
              </List.Item>
              <List.Item>
                <h4 className="key">{translate('general.creditAccount')}</h4>
                <h4 className="value">{`${transaction.creditAccount}`}</h4>
              </List.Item>
              <List.Item>
                <h4 className="key">{translate('general.createdAt')}</h4>
                <h4 className="value">
                  {moment(transaction.trxDate)
                    .tz(timeZones.kinshasa)
                    .format(`MMMM D, YYYY (h:mm a)`)}
                </h4>
              </List.Item>
              <List.Item>
                <h4 className="key">{translate('general.approvalStatus')}</h4>
                <h4 className="value">{`${transaction.approvalStatus}`}</h4>
              </List.Item>
              <List.Item>
                <h4 className="key">{translate('general.approvalStatus')}</h4>
                <h4 className="value">{`${transaction.approvalStatus}`}</h4>
              </List.Item>
              <List.Item>
                <h4 className="key">{translate('general.reason')}</h4>
                  <TextArea
                    style={{ width: "500px"}}
                  name="reason"
                  onChange={handleChange}
                  placeholder={translate('general.reason')}
                />
              </List.Item>
            </List>
          </div>
        </Col>
        </Row>
      </Form>
    </>
  )
}

export default TransactionDetail
